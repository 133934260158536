import { render, staticRenderFns } from "./EquipInfoDetail.vue?vue&type=template&id=72d16285&scoped=true&"
import script from "./EquipInfoDetail.vue?vue&type=script&lang=js&"
export * from "./EquipInfoDetail.vue?vue&type=script&lang=js&"
import style0 from "./EquipInfoDetail.vue?vue&type=style&index=0&id=72d16285&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d16285",
  null
  
)

export default component.exports