<template>
    <vvo v-slot="v">
        <div class="row flex-grow-1">
            <div class="col">
                <!-- TableList -->
                <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="detailInfo.calcProps"
                    :keyField="'propCode'" :transCodes="transCodes" :mode="mode" :useMaxHeight="false"
                    :controls="controls" :toggleFields="['enabled']" :useFilter="false" @row-click="handleListItemClick"
                    @toggle-changed="handleItemToggle" @button-click="handleListButtonClick" />
                <!-- <PropsModalComponent ref="PropsModalComponent" :propType="'calcType'" :mode="mode"
                    :typeDetail="typeDetail" /> -->
            </div>
        </div>
        <!-- @hidden="closeCalcPropModal" -->
        <b-modal ref="CalcPropDetailModal" centered @hidden="onValidate(v, closeCalcPropModal)">
            <template #modal-header>
                <h5>계산 속성 편집</h5>
            </template>
            <!-- @click="handleAddListItem" -->
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button v-show="isEditMode" type="button" class="btn btn-primary"
                        style="flex: 1;" :style="{ marginRight: isEditMode ? '0.5rem' : '' }"
                        @click="onValidate(v, handleAddListItem)">저장</button>
                        <!-- @click="closeCalcPropModal" -->
                    <button type="button" class="btn btn-secondary" 
                        style="flex: 1;" :style="{ marginLeft: isEditMode ? '0.5rem' : '' }"
                        @click="closeCalcPropModal">닫기</button>
                </div>
            </template>

            <CalcPropDetailPanel ref="CalcPropDetailPanel" :data="detailInfo" :typeDetail="typeDetail" :mode="mode" :hintList="hintList" />
        </b-modal>
        <b-modal ref="AddCalcPropsModal" centered @hidden="closeCalcUserPropModal">
            <template #modal-header>
                <h5>계산 속성 추가</h5>
            </template>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button class="btn btn-primary" 
                        style="flex: 1;" :style="{ marginRight: isEditMode ? '0.5rem' : '' }"
                        @click="addCalcProps">추가</button>
                    <button class="btn btn-secondary" 
                        style="flex: 1;" :style="{ marginLeft: isEditMode ? '0.5rem' : '' }"
                        @click="closeCalcUserPropModal">취소</button>
                </div>
            </template>
            <CalcPropAddPanel ref="CalcPropAddPanel" :detailInfo="detailInfo" :typeDetail="typeDetail" />
        </b-modal>
    </vvo>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
// import PropsModalComponent from "../modal/PropsModal.vue";
import CalcPropAddPanel from "./CalcPropAddPanel.vue"
import CalcPropDetailPanel from "./CalcPropDetailPanel.vue";

export default {
    components: {
        TableListComponent,
        // PropsModalComponent,
        CalcPropAddPanel,
        CalcPropDetailPanel,
    },
    props: ['detailInfo', 'mode', 'typeDetail'],
    created() {
        this.hintList = [...this.detailInfo.calcProps, ...this.detailInfo.ioProps, ...this.detailInfo.userProps]
    },
    mounted() {
    },
    data() {
        return {
            hintList: [],
            controls: [
                { event: 'delete', label: '-', class: 'btn-danger' },
                { event: 'regist', label: '+', class: 'btn-warning' },
            ],
            transCodes: [
                {
                    field: 'dataSourceType',
                    codes: this.$store.state.commonCodes.dataSourceType,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                    // compositionFields: ['propCode', 'propName']
                },
                {
                    label: this.$t('속성명'),
                    field: 'propName',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                    // compositionFields: ['propCode', 'propName']
                },
                // {
                //     label: this.$t('관제 유형'),
                //     field: 'dataSourceType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t('연결 관제점 주소'),
                    field: 'ptAddr',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                {
                    label: this.$t('관제점 ID'),
                    field: 'ptIdx',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center custom-ptIdx',
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                // {
                //     label: this.$t('단위 유형'),
                //     field: 'unitType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('계산값'),
                    field: 'ptVal',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('사용 여부'),
                    field: 'enabled',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                }
            ],
            inPropBuffer: null,

            tempCalcProp: null,
            tempCalcPropIndex: null,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    watch: {
        detailInfo() {




        }
    },
    methods: {
        async closeCalcPropModal() {
            await this.$refs.CalcPropDetailModal.hide();
            if (this.tempCalcPropIndex !== null || undefined) this.tempCalcPropIndex = null;
        },
        addCalcProps() {
            const getSelect = this.$refs.CalcPropAddPanel.selectProps();
            const filterProps = this.typeDetail.calcProps.filter(prop => getSelect.some(select => select.propCode == prop.propCode))
            filterProps.forEach(item => {
                this.detailInfo.calcProps.push({ ...item })
            })
            this.$refs.AddCalcPropsModal.hide();
        },
        async deleteProps() {
            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        this.detailInfo.calcProps = this.detailInfo.calcProps.filter(prop => {
                            return !checked.find(checkedProp => checkedProp.propCode == prop.propCode)
                        });
                    });
                } else {
                    this.alertNoti("삭제할 목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다");
                console.error(e);
            }
        },
        closeCalcUserPropModal() {
            this.$refs.AddCalcPropsModal.hide();
        },
        async registNew() {
            const that = this;
            // that.tempUserProp = null;

            that.$refs.AddCalcPropsModal.show();
        },
        async handleListButtonClick(event, options) {
            const that = this;


            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteProps();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            // if(toggle || check || !this.isEditMode) return;
            if (toggle || check) return;

            this.tempCalcPropIndex = index;
            let findCalcProp = this.detailInfo.calcProps.find(item => item.propCode == row.propCode)
            let detailData = this.typeDetail.calcProps.find(item => item.propCode == row.propCode)
            findCalcProp.calcMethod = detailData.calcMethod;
            await this.$refs.CalcPropDetailModal.show();
            await this.$refs.CalcPropDetailPanel.setData(findCalcProp);
        },
        handleAddListItem() {

            const calcProp = this.$refs.CalcPropDetailPanel.getData();

            if (calcProp) {
                if (this.tempCalcPropIndex !== null || undefined) {
                    this.$set(this.detailInfo.calcProps, this.tempCalcPropIndex, calcProp);
                    this.tempCalcPropIndex = null;
                } else return;
                // else {
                //     // Check Duplication (propCode, propName)
                //     let check = this.detailInfo.calcProps.find(prop => prop.propCode === ioProp.propCode || prop.propName === ioProp.propName);
                //     if (check) {
                //         // TODO: Alert Message
                //         console.error('TODO: Alert Message');
                //         console.error(`In Property is already exists in calcProps. propCode:${ioProp.propCode}, propName:${ioProp.propName}`);
                //     } else {
                //         this.$set(this.detailInfo.calcProps, this.detailInfo.calcProps.length, ioProp);
                //     }
                // }
            }


            this.$refs.CalcPropDetailModal.hide();
        },
        handleItemToggle(key, field, status) {

            const that = this;
            const found = that.detailInfo.calcProps.find(item => item.propCode === key)
            found.enabled = status
        },
    },
}
</script>

<style scoped>
.col {
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>