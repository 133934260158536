<template>
    <div>
        <!-- <div>{{ detailInfo.userProps }}</div>
        <div>{{ typeDetail.userProps }}</div> -->
        <!-- <div>{{ userPropList }}</div> -->
        <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="userPropList"
            :keyField="'propCode'" :transCodes="transCodes" :mode="'edit'" :useMaxHeight="false"
            :useFilter="false" />
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: ["detailInfo", "typeDetail"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            userPropList: null,
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('속성 코드명'),
                    field: 'propName',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('단위 유형'),
                    field: 'unitType',
                    type: 'text',
                },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                },
            ],
        }
    },
    created() { },
    mounted() {
        this.setUserPropList(this.detailInfo, this.typeDetail)
    },
    destroyed() {
    },
    methods: {
        setUserPropList(detail, typeDetail) {
            if (detail.userProps.length > 0) {
                if (typeDetail.userProps.length > 0) {
                    this.userPropList = typeDetail.userProps.filter(prop => !detail.userProps.some(user => prop.propCode == user.propCode));
                }
            } else {
                this.userPropList = typeDetail.userProps;
            }
        },
        selectProps() {
            return this.$refs.TableListComponent.getCheckedRows();
        }
    }
}

</script>