<template>
  <div class="container">
    <div class="row flex-grow-0 mb-3">
      <div class="col-md-6 p-0">
        <label class="col-md-12" for="connModule">연결된 연동 모듈</label>
        <div class="col-md-12">
          <div class="d-flex">
            <select v-model="selectedModuleIdx" class="form-control col-md-8 mr-3" id="connModule"
              :disabled="connectStatus == 'CONNECTED' || !isEditMode || ptMapType == 'Point'">
              <option :value="null">선택</option>
              <option v-for="(module, index) in moduleList" :key="index" :value="module.moduleIdx">
                {{ module.deviceAlias + ' / ' + module.moduleAlias }}</option>
            </select>
            <button v-if="!selectedModuleIdx && connectStatus == 'DISCONNECTED'" type="button" class="btn btn-primary"
              @click="openDeviceModal" :disabled="!isEditMode || ptMapType == 'Point'">모듈등록</button>
            <button v-else-if="selectedModuleIdx && connectStatus == 'DISCONNECTED'" type="button"
              class="btn btn-warning" @click="handleConnectModule" :disabled="!isEditMode || ptMapType == 'Point'">모듈연결</button>
            <button v-else-if="connectStatus == 'CONNECTED'" type="button" class="btn btn-danger"
              @click="handleDisConnectModule" :disabled="!isEditMode || ptMapType == 'Point'">연결해제</button>
            <!-- <button v-if="!detailInfo.moduleIdx && !detailInfo.equipName" type="button" class="btn btn-primary" @click="openDeviceModal" :disabled="!isEditMode">모듈등록</button> -->
          </div>
        </div>
      </div>
      <div class="col-md-2 p-0">
        <label class="col-md-12 d-flex align-items-center" for="equipIdx">
          <span>{{ $t('설비 ID') }}</span>
          <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
        </label>
        <div class="col-md-12">
          <input v-model="detailInfo.equipIdx" type="text" class="form-control" id="equipIdx" disabled>
        </div>
      </div>
      <div class="col-md-2 p-0">
        <label class="col-md-12" for="zoneInfo">적용 구역</label>
        <div class="col-md-12">
          <select v-model="detailInfo.zoneIdx" class="form-control" id="zoneInfo" :disabled="!isEditMode">
            <option :value="null">선택</option>
            <option v-for="code in $store.state.zoneInfo" :key="code.value" :value="code.value">{{
              code.text }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2 p-0">
        <label class="col-md-12" for="equipEnabled">사용여부</label>
        <div class="col-md-12">
          <select v-model="detailInfo.enabled" class="form-control" id="equipEnabled" :disabled="!isEditMode">
            <option :value="null">선택</option>
            <option v-for="(code, index) in $store.state.commonCodes.enabled" :key="index" :value="code.value">{{
              code.text }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row flex-grow-0 mb-3">
      <div class="col-md-3 p-0">
        <label class="col-md-12 d-flex align-items-center" for="equipType">
          <span>{{ $t('설비 유형 코드') }}</span>
          <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
        </label>
        <div class="col-md-12">
          <select v-model="detailInfo.equipType" class="form-control" id="equipType" :disabled="detailInfo.equipType">
            <option :value="null">선택</option>
            <option v-for="equip in equipTypeList" :key="equip.equipType" :value="equip.equipType">{{
              equip.equipTypeName }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3 p-0">
        <label class="col-md-12" for="equipName">
          <span>{{ $t('설비명') }}</span>
          <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
        </label>
        <div class="col-md-12">
          <!-- <input v-model="detailInfo.equipName" type="text" class="form-control" id="equipName" :disabled="!isEditMode"> -->
          <valid-input :vid="'설비명'" :classList="'form-control'" :inputType="'text'"
            :inputValue.sync="detailInfo.equipName" :placeholder="$t('설비명')" :rules="rules.COMMON_NAME_RULE"
            :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE" :disabled="!isEditMode"></valid-input>
        </div>
      </div>
      <div class="col-md-3 p-0">
        <label class="col-md-12 d-flex align-items-center" for="equipCategory">
          <span>{{ $t('설비 분류 코드') }}</span>
          <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
        </label>
        <div class="col-md-12">
          <select v-model="detailInfo.equipCategory" class="form-control" id="equipCategory" :disabled="!isEditMode">
            <option :value="null">선택</option>
            <option v-for="code in $store.state.commonCodes.equipCategory" :key="code.value" :value="code.value">{{
              code.text }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3 p-0">
        <label class="col-md-12" for="equipUseType">설비 용도 코드</label>
        <div class="col-md-12">
          <select v-model="detailInfo.equipUseType" class="form-control" id="equipUseType" :disabled="!isEditMode">
            <option :value="null">선택</option>
            <option v-for="code in $store.state.commonCodes.phscPointUsage" :key="code.value" :value="code.value">{{
              code.text }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row flex-grow-0 mb-3">
      <div class="col-md-12 p-0">
        <label class="col-md-12" for="equipDesc">설명</label>
        <div class="col-md-12">
          <!-- <textarea v-model="detailInfo.description" type="text" class="form-control" id="equipDesc"
            :disabled="!isEditMode" style="resize: none; overflow-y: auto;" rows="3"></textarea> -->
          <valid-textarea :inputType="'text'" :inputValue.sync="detailInfo.description" :NonStatus="true" :rows="3"
            :classList="'desc-ctn form-control'" :rules="rules.COMMON_DESC_RULE" :disabled="!isEditMode">
          </valid-textarea>
        </div>
      </div>
    </div>

    <!-- 서브 패널 영역 -->
    <div style="padding-left:5px; padding-right:5px; width:100%; height:calc(100% - 300px); overflow: hidden">
      <b-card no-body>
        <b-tabs card>
          <b-tab v-if="ptMapType == 'Point'" title="관제점 속성" active>
            <PointListPanel :detailInfo="detailInfo" :mode="mode" :typeDetail="equipTypeDetail" />
          </b-tab>
          <b-tab v-if="ptMapType !== 'Point'" title="계측 속성" active>
            <IoPropListPanel :detailInfo="detailInfo" :mode="mode" />
          </b-tab>
          <b-tab v-if="ptMapType !== 'Point'" title="계산 속성">
            <CalcPropListPanel :detailInfo="detailInfo" :mode="mode" :typeDetail="equipTypeDetail" />
          </b-tab>
          <b-tab v-if="ptMapType !== 'Point'" title="사용자 정의 속성">
            <UserPropListPanel :detailInfo="detailInfo" :mode="mode" :typeDetail="equipTypeDetail" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <!-- Seledct EquipType Modal -->
    <b-modal centered ref="SelectEquipTypeModal" @hidden="cancelSelectDriverModal()">
      <template #modal-header>
        <span style="font-size: 14px; font-weight: bold;">설비 유형 선택</span>
      </template>

      <template #modal-footer>
        <button type="button" class="btn btn-sm btn-primary" @click="handleSelectDriverModal()">선택</button>
        <button type="button" class="btn btn-sm btn-secondary" @click="cancelSelectDriverModal()">닫기</button>
      </template>

      <SelectEquipTypePanel ref="SelectEquipTypePanel" :mode="mode" />
    </b-modal>

    <vvo v-slot="v">
      <!-- Connect Device Module Modal -->
      <b-modal centered size="lg" ref="ConnectDeviceModal" @hidden="cancelConnectDeviceModal()">
        <template #modal-header>
          <span style="font-size: 14px; font-weight: bold;">연동 모듈 설정</span>
        </template>

        <template #modal-footer>
          <div style="display: flex; flex: 1;">
            <!-- @click="handleSaveModule" -->
            <button type="button" class="btn btn-lg btn-success" @click="onValidate(v, handleSaveModule)"
              style="flex: 1; margin-right: 5px;" :disabled="saveBtn">모듈 저장</button>
            <button v-if="isNewMode || !detailInfo.moduleIdx" type="button" class="btn btn-lg btn-warning"
              @click="handleConnectModule()" style="flex: 1; margin-right: 5px;" :disabled="connBtn">모듈
              연결</button>
            <button v-if="!isNewMode && detailInfo.moduleIdx" type="button" class="btn btn-lg btn-success"
              @click="handleDeleteModule()" style="flex: 1; margin-right: 5px;">모듈 삭제</button>
            <button v-if="!isNewMode && detailInfo.moduleIdx" type="button" class="btn btn-lg btn-warning"
              @click="handleDisConnectModule()" style="flex: 1; margin-right: 5px;">연결 해제</button>
            <button type="button" class="btn btn-lg btn-secondary" @click="cancelConnectDeviceModal()"
              style="flex: 1;">닫기</button>
          </div>
        </template>

        <DeviceModulePanel ref="DeviceModulePanel" :mode="mode" :data="detailInfo" :type="type"
          @update-moduleIndex="catchModuleIndex" />
      </b-modal>
    </vvo>

  </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

import SelectEquipTypePanel from "./SelectEquipTypePanel.vue";
import DeviceModulePanel from "./DeviceModulePanel.vue";

import IoPropListPanel from "./IoPropListPanel.vue";
import CalcPropListPanel from "./CalcPropListPanel.vue";
import UserPropListPanel from "./UserPropListPanel.vue";
import PointListPanel from "./PointListPanel.vue";
// import FacilitySpecPanel from "./FacilitySpecPanel";
import backEndApi from "@src/api/backEndApi";
//import FacilityListPanel from "../../equip-type/panels/FacilityListPanel.vue";

export default {
  components: {
    SelectEquipTypePanel,
    DeviceModulePanel,
    IoPropListPanel,
    CalcPropListPanel,
    UserPropListPanel,
    PointListPanel,
    //FacilitySpecPanel,
    // FacilityListPanel,
  },
  props: ['detailInfo', 'mode', 'moduleList', 'type'],
  data() {
    return {
      equipType: '',
      selectedModuleIdx: null,
      selectedDriverProps: [],
      saveModuleResult: null,
      connModuleResult: null,
      saveBtn: true,

      equipTypeDetail: null,

      ptMapType: null,
      rules,
      validMessage,
    }
  },
  async created() {
    await this.getEquipTypeDetail();
  },
  mounted() {
    this.selectedModuleIdx = this.detailInfo.moduleIdx;
    if (this.isNewMode) this.$refs.SelectEquipTypeModal.show();
  },
  computed: {
    isEditMode() {
      return this.mode !== 'view';
    },
    isNewMode() {
      return this.mode === 'new';
    },
    equipTypeList() {
      try {
        return this.$store.state.equipTypeList;
      } catch (e) {
        console.error(e)
        return [];
      }
    },
    connectStatus() {
      return (this.selectedModuleIdx && this.detailInfo.moduleIdx == this.selectedModuleIdx) ? 'CONNECTED' : 'DISCONNECTED';
    },
    connBtn() {
      if (this.saveModuleResult) {
        let conn = this.saveModuleResult.moduleIdx ? false : true;
        return conn;
      }
      return true;
    }
  },
  watch: {
    detailInfo() {
      this.selectedModuleIdx = this.detailInfo.moduleIdx;
    },
    'detailInfo.moduleIdx': {
      handler(newVal) {
        if (newVal && this.detailInfo.equipName && this.detailInfo.equipName.length <= 0) {
          this.detailInfo.equipName = this.moduleList.find(item => item.moduleIdx == newVal)?.moduleAlias;
        }
      },
      immediate: true,
      deep: true,
    },
    isNewMode() {
      if (this.isNewMode) this.$refs.SelectEquipTypeModal.show();
    }
  },
  methods: {
    async getEquipTypeDetail() {
      try {
        let result = await backEndApi.equipTypes.getEquipType(this.type, "Y");
        if (result.status == 200) {
          this.equipTypeDetail = result.data;
          this.ptMapType = this.equipTypeDetail.ptMapType ? this.equipTypeDetail.ptMapType : "";
        }

      } catch (e) {
        console.error(e)
      }
    },
    cancelSelectDriverModal() {
      this.$refs.SelectEquipTypeModal.hide();
      this.equipType = null;
    },
    async handleSelectDriverModal() {
      try {
        this.equipType = this.$refs.SelectEquipTypePanel.getEquipType();

        await this.$emit('getModuleList', this.equipType);

        let result = await backEndApi.equipTypes.getEquipType(this.equipType, "Y")
        if (result && result.data) {
          let found = result.data
          // Initializing detailInfo with driver info.
          this.detailInfo.equipType = found.equipType;
          this.detailInfo.equipCategory = found.equipCategory;
          this.detailInfo.enabled = "Y";
          this.detailInfo.ioProps = found.ioProps ? found.ioProps : null;
          this.detailInfo.calcProps = found.calcProps ? found.calcProps : null;
          this.detailInfo.userProps = found.userProps ? found.userProps : null;
        } else {
          console.error('Driver Info is not found. selectedDrver:', this.equipType);
          this.selectedDriverProps = null;
        }

        this.$refs.SelectEquipTypeModal.hide();
      } catch (e) {
        console.error(e)
        this.$refs.SelectEquipTypeModal.hide();
      }
    },
    async handleSaveModule() {
      let module = this.$refs.DeviceModulePanel.module;

      if (module.moduleIndex == null || module.moduleIndex == undefined) {
        this.alertWarning("모듈 인덱스를 선택해주세요.")
        return;
      }

      try {
        let result = await backEndApi.equips.saveModule(module);
        if (result.data) {
          this.saveModuleResult = result.data.data;
          // await this.$emit('getModuleList', this.detailInfo.equipType);
          // this.detailInfo.moduleIdx = this.saveModuleResult.moduleIdx;
          // this.detailInfo.equipUseType = this.saveModuleResult.equipUseType;
          // this.detailInfo.zoneIdx = this.saveModuleResult.applyZoneIdx;

          this.alertNoti("모듈을 저장하였습니다. 모듈을 연결해 주세요.")
        }
      } catch (e) {
        console.error(e)
      }
    },
    async handleConnectModule() {
      let connModule, module;


      if (this.saveModuleResult) {
        // 모듈을 신규 생성한 후 연결하는 것이라면 생성 결과로 부터 연결을 설정함
        module = this.$refs.DeviceModulePanel.module;
        connModule = {
          equipType: module.equipType,
          deviceIdx: module.deviceIdx,
          moduleIdx: this.saveModuleResult.moduleIdx,
        }
      } else {
        // 모듈을 선택한 후 연결하는 것이라면 모듈 정보를 조회하여 연결을 설정함
        module = this.moduleList.find(module => module.moduleIdx == this.selectedModuleIdx);
        connModule = {
          equipType: this.$route.query.equipType,
          deviceIdx: module.deviceIdx,
          moduleIdx: this.selectedModuleIdx,
        }
      }

      try {

        // 장치의 모듈 연결에 필요한 사전 준비 처리 (필요한 관제점 생성 등, 기존 관제점은 재사용)
        let result = await backEndApi.equips.prepareModuleConnect(connModule);
        if (result.data) {

          let filterModuleInfo;

          this.detailInfo.moduleIdx = this.selectedModuleIdx;
          this.detailInfo.equipName = module.moduleAlias;
          this.detailInfo.moduleIdx = this.saveModuleResult ? this.saveModuleResult.moduleIdx : this.detailInfo.moduleIdx;
          this.detailInfo.equipUseType = this.saveModuleResult ? this.saveModuleResult.equipUseType : null;
          this.detailInfo.zoneIdx = this.saveModuleResult ? this.saveModuleResult.applyZoneIdx : null;
          this.connModuleResult = result.data.data

          // if(this.saveModuleResult) {
          //     filterModuleInfo = this.saveModuleResult.filter(module => 
          //         this.detailInfo.ioProps.some(prop => prop.propCode == module.propCode)
          //     )
          // } else {
          //     filterModuleInfo = this.connModuleResult.filter(module => 
          //         this.detailInfo.ioProps.some(prop => prop.propCode == module.propCode)
          //     )

          // }
          filterModuleInfo = this.connModuleResult.filter(module =>
            this.detailInfo.ioProps.some(prop => prop.propCode == module.propCode)
          )

          this.detailInfo.ioProps.forEach(ioProp => {
            let found = filterModuleInfo.find(item => item.propCode === ioProp.propCode);
            if (found) {

              this.$set(ioProp, "ptAddr", found.ptAddr);
              this.$set(ioProp, "ptIdx", found.ptIdx);
            }
          })

          this.detailInfo.ioProps = [...this.detailInfo.ioProps];
          // this.$forceUpdate();

          await this.$emit('getModuleList', this.detailInfo.equipType);

          this.alertNoti("모듈을 연결하였습니다.");

          if (this.saveModuleResult) this.$refs.ConnectDeviceModal.hide();
        }
      } catch (e) {
        console.error(e);
        this.alertNoti("모듈을 연결에 실패했습니다.");
      }
    },
    async handleDisConnectModule() {
      let confirm = await this.alertConfirmWarning("연결된 모듈을 해제하시겠습니까?");
      if (confirm.value) {

        this.detailInfo.ioProps.forEach(ioProp => {
          // this.$set(ioProp, "ptAddr", undefined);
          // this.$set(ioProp, "ptIdx", undefined);
          this.$delete(ioProp, "ptAddr");
          this.$delete(ioProp, "ptIdx");
        });
        await this.$emit('getModuleList', this.detailInfo.equipType);

        this.detailInfo.moduleIdx = null;
        // 모듈명을 설비명으로 사용하는 경우 설비명을 빈문자열로 대체함
        // 모듈 변경시 설비명이 비어 있어야 모듈명을 초기값으로 설정해주기 때문.
        if (this.moduleName == this.detailInfo.equipName) {
          this.detailInfo.equipName = null;
        }

        if (this.saveModuleResult) await this.$refs.DeviceModulePanel.clearData();

        await this.alertNoti("해제하였습니다.");
      }
      else return;
    },
    async handleDeleteModule() {
      if (!this.connectStatus == 'CONNECTED') {
        this.alertNoti("모듈 연결을 해제하고 삭제해 주세요");
        return;
      } else {
        const confirm = await this.alertConfirmWarning("모듈을 삭제하시겠습니까?");
        if (confirm.value) {
          let result = await backEndApi.equips.deleteModule(this.detailInfo.moduleIdx, "Y");
          if (result.data) {
            await this.alertNoti("모듈을 삭제하였습니다.");
            // await this.$emit("refresh");
            await this.$emit("clearData");
          } else {
            this.alertNoti("삭제에 실패하였습니다.")
          }
        }
        else return;
      }
    },
    cancelConnectDeviceModal() {
      this.$refs.ConnectDeviceModal.hide();
    },
    async openDeviceModal() {
      this.$refs.ConnectDeviceModal.show();
    },
    catchModuleIndex(index) {
      this.saveBtn = index === null;
    },
  }

}
</script>

<style scoped>
.container {
  height: 100%;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 4px;
  padding-left: 4px;
}

.control-icon {
  /* font-size: 68px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #455A64;
  background-color: #ECEFF1;
  transition: background-color 0.5s;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-left: solid 1px #CFD8DC;
  border-top: solid 1px #CFD8DC;
  border-right: solid 1px #90A4AE;
  border-bottom: solid 1px #90A4AE;
}

.control-icon:hover {
  color: #ECEFF1;
  background-color: #455A64;
}
</style>
