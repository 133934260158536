<template>
    <div v-if="detailInfo" class="detail-container">
        <div class="detail-content">
            <div class="row">
                <div class="col-md-4">
                    <label for="ptReltnType">관제점 관계 유형</label>
                    <select v-model="detailInfo.ptReltnType" class="form-control" id="ptReltnType"
                        :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(type) in $store.state.commonCodes.ptReltnType" :key="type.value"
                            :value="type.value">
                            {{ type.text }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="enabled">사용 여부</label>
                    <select v-model="detailInfo.enabled" class="form-control" id="enabled" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(type) in $store.state.commonCodes.enabled" :key="type.value"
                            :value="type.value">
                            {{ type.text }}
                        </option>
                    </select>
                </div>
                <!-- <div class="col-md-3">
                    <label for="ptIdx">관제점 ID</label>
                    <input v-if="edit" v-model="detailInfo.ptIdx" type="text" class="form-control" id="ptIdx" disabled>
                    <input v-else v-model="ptIdxs" type="text" class="form-control" id="ptIdx" disabled>
                </div> -->
                <div class="col-md-4" style="display: flex; align-items: end; justify-content: end;">
                    <button v-b-toggle.equipPointSearch type="button" class="btn btn-primary"
                        @click="search" :disabled="!isEditMode">관제점 선택</button>
                </div>
                <div class="col-md-12 mt-2">
                    <label for="ptIdx">관제점 ID</label>
                    <textarea v-if="edit" v-model="detailInfo.ptIdx" class="form-control" id="ptIdx" rows="3"
                        style="resize: none;" :placeholder="'직접 입력할 경우 예시(123, 456) 와 같은 형태로 입력해주세요.'"></textarea>
                    <textarea v-else v-model="ptIdxs" class="form-control" id="ptIdx" rows="3"
                        style="resize: none;" :placeholder="'직접 입력할 경우 예시(123, 456) 와 같은 형태로 입력해주세요.'"></textarea>
                </div>
            </div>
        </div>
        <b-sidebar v-model="sideBar" ref="equipPointSearch" id="equipPointSearch" bg-variant="white"
            backdrop-variant="secondary" width="50vw" @hidden="closeSidebar" no-header shadow right backdrop>
            <div v-if="sideBar" style="padding: 1rem; height: 100%">
                <SearchComponent :tableMaxHeight="'75vh'" :optionTableHeight="'67vh'" :mode="'multi'"
                    @select-point="selectPoint" @close="closeSidebar" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
// import backEndApi from '@src/api/backEndApi';
import SearchComponent from '@src/views/component/v2.1/PointSearch/Search.vue';

export default {
    components: {
        SearchComponent
    },
    props: ["mode"],
    data() {
        return {
            detailInfo: null,
            sideBar: false,
            ptIdxs: "",
            edit: false,
        }
    },
    watch: {},
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    created() { },
    mounted() { },
    destroyed() { },
    methods: {
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.equipPointSearch.hide();
            this.sideBar = false;
        },
        initData() {
            return {
                ptIdx: null,
                ptReltnType: "Include",
                enabled: "Y",
            }
        },
        setData(data) {
            if (data) {
                this.edit = true;
                this.detailInfo = data;
            } else {
                this.edit = false;
                this.detailInfo = this.initData();
            }
        },
        getData() {
            return this.detailInfo;
        },
        getPtIdxs() {
            return this.ptIdxs;
        },
        search() {
            this.$emit("search-point");
        },
        async selectPoint(data) {
            if (this.edit) {
                this.detailInfo.ptIdx = data[0].ptIdx;
            } else {
                if (data) {
                    if (data.length > 1) {
                        data.forEach(item => {
                            if (item.ptIdx == data[0].ptIdx) this.ptIdxs += item.ptIdx;
                            else this.ptIdxs += `, ${item.ptIdx}`
                        })
                    } else this.ptIdxs += data[0].ptIdx;
                }
            }
            await this.$refs.equipPointSearch.hide();
        },
    },
}

</script>

<style scoped>
.detail-container {
    height: 100%;
    /* height: 400px; */
    /* width: 100%; */
    /* border-radius: 8px; */
    /* border: solid #eee 1px; */
    /* background-color: white; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 100%;
    width: 100%;
    /* border-radius: 0px 0px 8px 8px; */
    /* border: solid #eee 1px; */
    /* padding: 1rem 1rem; */
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.btn-sm {
    height: 1.4rem !important;
    line-height: 0.9 !important;
}
</style>