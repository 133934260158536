<template>
    <div class="row">
        <div class="row w-100 mb-3 mr-0 ml-0">
            <div class="col-md-4 p-0">
                <label class="col-md-12" for="connDevice">연동 장치명(*)</label>
                <div class="col-md-12">
                    <select v-model="device.deviceName" class="form-control" id="connDevice"
                        @change="foundDeviceModule(device.deviceName)"
                        :disabled="this.mode !== 'new' && data.moduleIdx">
                        <option :value="null">미지정</option>
                        <option v-for="device in deviceList" :key="device.deviceIdx" :value="device.deviceName">{{
                            device.deviceName }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4 p-0">
                <label class="col-md-12" for="connDriver">연동 드라이버(*)</label>
                <div class="col-md-12">
                    <input v-model="device.driverType" type="text" class="form-control" id="connDriver"
                        :disabled="this.mode !== 'new' && data.moduleIdx">
                </div>
            </div>
            <div class="col-md-4 p-0">
                <label class="col-md-12" for="driverVersion">버전(*)</label>
                <div class="col-md-12">
                    <input v-model="device.version" type="text" class="form-control" id="driverVersion"
                        :disabled="this.mode !== 'new' && data.moduleIdx">
                </div>
            </div>
        </div>
        <div class="row w-100 mb-3 mr-0 ml-0">
            <div class="col-md-4 p-0">
                <label class="col-md-12" for="moduleIndex">모듈 인덱스(*)</label>
                <div class="col-md-12" id="moduleIndex">
                    <div class="row">
                        <div class="col-md-3">
                            <button class="btn btn-primary" @click="toggleConversion"
                                :disabled="this.mode !== 'new' && data.moduleIdx">{{ isHEX ? "HEX" : "DEC" }}</button>
                        </div>
                        <div class="col-md-9">
                            <select v-model="module.moduleIndex" class="form-control"
                                :disabled="this.mode !== 'new' && data.moduleIdx" @change="emitModuleIndex">
                                <option :value="null">선택</option>
                                <option v-for="(module, index) in device.unusedModules" :key="index"
                                    :value="Number(module)">{{
                                        module }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <label class="col-md-12" for="moduleName">서브 모듈명(*)</label>
                <div class="col-md-12">
                    <valid-input :vid="'서브 모듈명'" :inputType="'text'" :inputValue.sync="module.moduleName"
                        :rules="rules.COMMON_NAME_RULE" :placeholder="$t('서브 모듈명')"
                        :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"></valid-input>
                    <!-- <input v-model="module.moduleName" type="text" class="form-control" id="moduleName"> -->
                </div>
            </div>
            <div class="col-md-3 p-0">
                <label class="col-md-12" for="moduleAlias">모듈 표시명(*)</label>
                <div class="col-md-12">
                    <!-- <input v-model="module.moduleName" type="text" class="form-control" id="moduleName" disabled> -->
                    <valid-input :vid="'모듈 표시명'" :inputType="'text'" :inputValue.sync="module.moduleAlias"
                        :rules="rules.COMMON_NAME_RULE"
                        :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"></valid-input>
                </div>
            </div>
            <div class="col-md-2 p-0">
                <label class="col-md-12" for="moduleIdx">모듈 ID</label>
                <div class="col-md-12">
                    <input v-model="module.moduleIdx" type="text" class="form-control" id="moduleIdx" disabled>
                </div>
            </div>
        </div>
        <div class="row w-100 mb-3 mr-0 ml-0">
            <div class="col-md-3 p-0">
                <label class="col-md-12" for="mappingKey">맵핑키</label>
                <div class="col-md-12">
                    <input v-model="module.mappingKey" type="text" class="form-control" id="mappingKey">
                </div>
            </div>
            <div class="col-md-3 p-0">
                <label class="col-md-12" for="applyZoneIdx">적용 구역</label>
                <div class="col-md-12">
                    <select v-model="module.applyZoneIdx" class="form-control" id="applyZoneIdx">
                        <option :value="null">선택</option>
                        <option v-for="zone in $store.state.zoneInfo" :key="zone.value" :value="zone.value">{{
                            zone.text }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <label class="col-md-12" for="energySplyTypeCode">에너지 공급 구분</label>
                <div class="col-md-12">
                    <select v-model="module.energySplyTypeCode" class="form-control" id="energySplyTypeCode">
                        <option :value="null">선택</option>
                        <option v-for="typeCode in $store.state.commonCodes.energySplyTypeCode" :key="typeCode.value"
                            :value="typeCode.value">{{
                                typeCode.text }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <label class="col-md-12" for="equipUseType">설비 용도</label>
                <div class="col-md-12">
                    <select v-model="module.equipUseType" class="form-control" id="equipUseType">
                        <option :value="null">선택</option>
                        <option v-for="ptUsage in $store.state.commonCodes.phscPointUsage" :key="ptUsage.value"
                            :value="ptUsage.value">{{
                                ptUsage.text }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row w-100 mr-0 ml-0">
            <div class="col-md-12 p-0">
                <label class="col-md-12" for="facilityOption">모듈 옵션</label>
                <div class="col-md-12">
                    <!-- <input v-model="module.facilityOption" type="text" class="form-control" id="facilityOption"> -->
                    <valid-input :vid="'모듈 옵션'" :inputType="'text'" :inputValue.sync="module.moduleName"
                        :rules="rules.COMMON_NAME_RULE" :placeholder="$t('모듈 옵션')" :NonStatus="true">
                    </valid-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

export default {
    components: {},
    props: ['mode', 'data', 'type'],
    async created() { },
    async mounted() {
        await this.getDeviceList();
        if (this.mode !== 'new' && this.data.moduleIdx) await this.setModuleData();
    },
    data() {
        return {
            deviceList: null,
            device: {
                deviceName: null,
                driverType: null,
                version: null,
                maxIndex: null,
                unusedModules: null,
            },
            module: {
                equipType: this.data.equipType,
                deviceIdx: null,
                moduleIdx: null,
                moduleName: null,
                // moduleIndex: null,
                moduleAlias: null,
                mappingKey: null,
                applyZoneIdx: null,
                energySplyTypeCode: null,
                equipUseType: null,
                facilityOption: null,
            },
            isHEX: false,
            rules,
            validMessage
        };
    },
    computed: {},
    watch: {
        'module.moduleName': {
            handler(newVal) {
                if (newVal) this.module.moduleAlias = newVal;
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        async getDeviceList() {
            let type = this.data.equipType ? this.data.equipType : this.type
            const that = this;
            try {
                let result = await backEndApi.equips.getDeviceList(type, "Y")
                if (result && result.data) that.deviceList = result.data;
            } catch (e) {
                console.error(e)
            }
        },
        foundDeviceModule(deviceName) {
            const that = this;
            let device = that.deviceList.find(device => device.deviceName === deviceName)

            if (device) {
                if (device.unusedModules.length == 0) {
                    this.alertNoti("이 장치는 비어있는 서브 모듈이 없습니다.");
                    return;
                } else {
                    let transUnusedModules = device.unusedModules.split(',');
                    transUnusedModules.forEach(num => parseInt(num));

                    that.module.deviceIdx = device.deviceIdx;
                    that.device.driverType = device.driverType;
                    that.device.version = device.version;
                    that.device.unusedModules = transUnusedModules;
                    that.device.maxIndex = device.maxIndex;
                }
            }
        },
        async setModuleData() {
            try {
                let result = await backEndApi.equips.getModuleList(this.data.equipType, "Y", this.data.moduleIdx);
                if (result.data) {
                    this.device.deviceName = result.data[0].deviceName;
                    if (this.device.deviceName) {
                        this.foundDeviceModule(this.device.deviceName);
                        this.device.unusedModules.push(result.data[0].moduleIndex);
                    }
                    this.module.moduleIndex = result.data[0].moduleIndex;
                    this.module.moduleName = result.data[0].moduleName;
                    this.module.moduleIdx = result.data[0].moduleIdx;
                    this.module.mappingKey = result.data[0].mappingKey;
                    this.module.applyZoneIdx = this.data.zoneIdx;
                    this.module.energySplyTypeCode = result.data[0].energySplyTypeCode;
                    this.module.equipUseType = this.data.equipUseType;
                    this.module.facilityOption = result.data[0].facilityOption;
                }
            } catch (e) {
                console.error(e)
            }
        },
        toggleConversion() {
            this.isHEX = !this.isHEX;  // 변환 모드 토글
            this.convertNumbers();  // 숫자 변환 실행
        },
        convertNumbers() {
            if (this.isHEX) {
                // 10진수에서 16진수로 변환
                this.device.unusedModules = this.device.unusedModules.map(num => Number(num).toString(16).toUpperCase());
            } else {
                // 16진수에서 10진수로 변환
                this.device.unusedModules = this.device.unusedModules.map(num => parseInt(num, 16));
            }
        },
        emitModuleIndex() {
            this.$emit("update-moduleIndex", this.module.moduleIndex);
        },
        clearData() {
            this.module = {
                equipType: this.data.equipType,
                deviceIdx: null,
                moduleIdx: null,
                moduleName: null,
                moduleIndex: null,
                mappingKey: null,
                applyZoneIdx: null,
                energySplyTypeCode: null,
                equipUseType: null,
                facilityOption: null,
            };
            this.device = {
                deviceName: null,
                driverType: null,
                version: null,
                maxIndex: null,
                unusedModules: null,
            };
        }
    },
}
</script>